@use "../../../../../styles/variables.module" as *;

.linkItem {
  word-break: break-word;

  a {
    color: $gray;
  }

  > span:first-of-type {
    font-size: 0.8em;
    vertical-align: middle;
    margin-right: 0.6rem;
    text-align: left;
    color: #6d6c6c;
    width: 1.25rem;
    display: inline-block;
  }

  > span:last-of-type {
    font-size: 1.2em;
    vertical-align: top;
    width: calc(100% - 1.25rem - .6rem);
    display: inline-block;
  }

  &Folder {
    font-weight: 600;
    margin-bottom: .25rem;
    color: $black;

    > span {
      cursor: pointer;
      color: $black;
    }

    a {
      font-weight: normal;
    }

    &Closed > span:first-of-type {
      transform: rotate(-90deg);
    }
  }
  &OnTerrace {
    a {
      font-size: $terrace-body-font-size;
      @media(max-width: $break-point-sm) {
        font-size: $terrace-body-font-size-mobile;
      }
    }

    > span:last-of-type {

      @media(max-width: $break-point-sm) {
        font-size: $terrace-subheading-font-size-mobile;
      }
    }

    &Folder {
      color: $black;
      font-size: $terrace-body-font-size;

      > span {
        color: $black;
      }
    }
  }
}

.blockList {
  ul {
    padding-left: 0;
    list-style: none;
    margin-top: 10px;

    ul {
      padding-left: calc(1.25em + .6rem);
    }

    li {
      margin-bottom: 0.5em;
      position: relative;

      li:last-child {
        margin-bottom: 1em;
      }
    }

    li:last-child {
      margin-bottom: 0;
    }
  }

  &.sbSub {
    // by default, all lists will be 1 column wide and take up 100% of the block width.
    // see the media query section (below) for additional width changes per block size, depending on screen size
    li {
      float: left;
      margin-right: 0.5em;
      width: 100%;
    }
  }
}

.editNodeTitle {
  span {
    margin-right: .5rem;
  }
}

.treeNode:hover {
  .editNodeButton {
    visibility: visible;
    opacity: 1;
  }
}

.editNodeButton {
  background: $admin-blue-dark;
  color: white;
  vertical-align: middle;
  padding: 8px 10px;
  border-radius: 50%;
  margin-left: 3px;
  transition: opacity 300ms;
  font-weight: 600;
  font-size: 1.2rem;
  visibility: hidden;
  opacity: 0;

  &:hover {
    background: $admin-green-dark;
  }
}
